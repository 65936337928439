"use client";

import { useState, useRef } from "react";

import VideoModal from "@/components/VideoModal/VideoModal";

import Avatar from "@/public/png/common/happy-customers/suhaina.png";
import Avatar1 from "@/public/png/common/happy-customers/manojkumar.png";

import Avatar2 from "@/public/png/common/happy-customers/shalini.png";

import Avatar3 from "@/public/png/common/happy-customers/prashanth.png";

import Avatar4 from "@/public/png/common/happy-customers/harshini.png";

import Picture from "@/public/png/common/happy-customers/common-reviews-img-1.jpg";
import Picture1 from "@/public/png/common/happy-customers/andaman-reviews-1.jpg";

import Picture2 from "@/public/png/common/happy-customers/common-reviews-img-2.jpg";

import Picture4 from "@/public/png/common/happy-customers/bali-reviews-img.jpg";
import Picture5 from "@/public/png/common/happy-customers/common-reviews-img-3.jpg";

import Review from "@/public/svg/common/happy-customers/google-review.svg";

import "@/styles/common/customer-reviews.scss";
import "@/styles/common/typo.scss";
import "./happy-customers.scss";

import Button from "@/components/button/Button";
import Heading from "@/components/heading/heading";

import Image from "next/image";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const REVIEWS1 = [
    {
        id: 1,
        name: "Suhaina Abdul Samath",
        review: "Thank you dimaak team for perfectly  arranging our family trip to singapore.highly recommended for helping in customizing and arranging things as per our needs , a very flexible team.and good team work. Kudos to the entire team. Special thanks to subash who was with us throughout the planning and aravinth who was constantly arranging all our schedules and hotel accomdations after reaching singapore. Would definitely recommend dimaak and hoping to plan our future trips too with Dimaak.",
        image: Avatar,
    },
    {
        id: 2,
        showImage: true,
        image: Picture,
    },
    {
        id: 3,
        name: "Manojkumar Balamurugan",
        review: "Very nice arrangement done by Dimaak tours…..I specially want to thank Mr.Manikandan 👍👏for his excellent service….He did his best to make my trip memorable forever……Keep it up……..",
        image: Avatar1,
    },
    {
        id: 4,
        showImage: true,
        image: Picture1,
        thumbnail: Picture1,
        videoId: "OBTFqa1llnw",
        videoTitle: "Andaman",
    },
    {
        id: 5,
        name: "Shalini Devi Ari",
        review: "I was a solo traveller and the destination  was Vietnam . Greatly appreciate the individual  customisation of their itinerary. The team is proactive in solving an issue that may arise too. Highly dependable and would definitely recommend them for anyones holiday plans .",
        image: Avatar2,
    },]

const REVIEWS2 = [{
    id: 1,
    showImage: true,
    image: Picture2,
},
{
    id: 2,
    name: "Prasanth Moorthi",
    review: "Mr.Mani, Dimak Tours organised us a memorable trip to Maldives. Everything was very well guided. Right from the start he was very patient listening to all our expectations and provided the service we required. It took us a lot of time in selecting the resort and we keeping asking him for different types of quotations for each and every resort. He was very patient and provided us even during late hours. At last he suggested a fantastic resort for us which gave a lifetime memory. His financial team was very quick in providing the details and helped on time. I would strongly recommend to book through Dimak Tours for a hassle free travel experience & Thanks Mani sir once again for all your contribution. Looking forward to travel with Dimak more !",
    image: Avatar3,
},
{
    id: 3,
    showImage: true,
    image: Picture4,
    thumbnail: Picture4,
    videoId: "9scRCso_YOo",
    videoTitle: "Bali",
},
{
    id: 4,
    name: "Harshini Sri",
    review: "We visited Maldives last week. Raj has been very responsive and helped us till our return. The arrangements were made such that we do not have to worry about anything.",
    image: Avatar4,
},
{
    id: 5,
    showImage: true,
    image: Picture5,
},
];

const HappyCustomers = () => {
    const [modalStates, setModalStates] = useState(REVIEWS1.map(() => false));
    const [modal2States, setModal2States] = useState(REVIEWS2.map(() => false));
    const swiperRef1 = useRef<any>(null);
    const swiperRef2 = useRef<any>(null);

    const handleEnter = () => {
        swiperRef1.current.swiper.autoplay.stop();
        swiperRef2.current.swiper.autoplay.stop();
    };

    const handleLeave = () => {
        swiperRef1.current.swiper.autoplay.start();
        swiperRef2.current.swiper.autoplay.start();
    };

    const openModal = (index: number, reviewsListNo: number) => {
        if (reviewsListNo === 1) {
            const newModalStates = [...modalStates];
            newModalStates[index] = true;
            setModalStates(newModalStates);
        } else {
            const newModalStates = [...modal2States];
            newModalStates[index] = true;
            setModal2States(newModalStates);
        }
    };

    const closeModal = (index: number, reviewsListNo: number) => {
        if (reviewsListNo === 1) {
            const newModalStates = [...modalStates];
            newModalStates[index] = false;
            setModalStates(newModalStates);
        } else {
            const newModalStates = [...modal2States];
            newModalStates[index] = false;
            setModal2States(newModalStates);
        }
    };

    return (
        <section className="happy-customers-section">
            <div className="container px-4 mx-auto flex flex-col gap-6 sm:gap-14">
                <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
                    <div className="happy-customers-block col-span-1 flex flex-col justify-center items-start gap-3 sm:gap-6 lg:col-span-1">
                        <small className="font-yesteryear font-extralight">
                            Our Customers Love
                        </small>
                        <Heading as="h2">Travel Experiences</Heading>
                        <p className="lead">
                            Discover what our valued customers have to say about their unforgettable experiences. Read genuine reviews and testimonials showcasing the joy and satisfaction of their journeys with us
                        </p>
                        <div className="button-review flex flex-wrap items-center gap-6">
                            <Button
                                onClick={() =>
                                    window.open(
                                        "https://bit.ly/dimaakreviews",
                                        "_blank"
                                    )
                                }
                                className="flex items-center"
                                variant="orange"
                            >
                                <span>View more Reviews</span>
                            </Button>
                            <Image loading="lazy"
                                src={Review}
                                alt="Review"
                                className="review"
                            />
                        </div>
                    </div>
                    <div className="happy-customers-slider happy-customers-slider-vertical col-span-1 flex-col gap-4 lg:col-span-2 hidden lg:flex">
                        <div
                            className="grid-cols-1 gap-6 lg:grid-cols-2 grid"
                            onMouseEnter={handleEnter}
                            onMouseLeave={handleLeave}
                        >
                            <div className="happy-customers-slider-one happy-customers-slider col-span-1 flex flex-col gap-4 lg:col-span-1">
                                <Swiper
                                    ref={swiperRef1}
                                    slidesPerView={1.5}
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    direction="vertical"
                                    speed={3000}
                                    modules={[Autoplay]}
                                    autoplay={{
                                        delay: 1,
                                        disableOnInteraction: false,
                                        waitForTransition: true,
                                        pauseOnMouseEnter: true,
                                    }}
                                    loop={true}
                                    className="swiper-container"
                                    autoHeight={true}
                                    height={400}
                                >
                                    {REVIEWS1?.map((review, index) => (
                                        <SwiperSlide
                                            key={review.id}
                                            className={`swiper-slide ${review.showImage
                                                ? "with-image-h"
                                                : "without-image-h"
                                                }`}
                                            style={{
                                                height: "100% !important",
                                                color: "red",
                                            }}
                                        >
                                            {review.showImage ? (
                                                <div className="with-image h-full">
                                                    {review.videoId ? (
                                                        <div
                                                            className="picture object-fill h-full"
                                                            onClick={() =>
                                                                openModal(index, 1)
                                                            }
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            <Image loading="lazy"
                                                                src={
                                                                    review.thumbnail
                                                                }
                                                                alt="Picture"
                                                                className="picture object-fill h-full"
                                                            />
                                                            <div
                                                                className="play-button"
                                                                style={{
                                                                    position:
                                                                        "absolute",
                                                                    top: "50%",
                                                                    left: "50%",
                                                                    transform:
                                                                        "translate(-50%, -50%)",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                <svg
                                                                    width="61"
                                                                    height="61"
                                                                    viewBox="0 0 61 61"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <circle
                                                                        cx="30.5"
                                                                        cy="30.5"
                                                                        r="30.5"
                                                                        fill="#FFF3ED"
                                                                    />
                                                                    <path
                                                                        d="M42 30L24 40.3923L24 19.6077L42 30Z"
                                                                        fill="#FF4514"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <Image loading="lazy"
                                                            src={review.image}
                                                            alt="Picture"
                                                            className="picture object-fill h-full"
                                                        />
                                                    )}

                                                    <VideoModal
                                                        videoTitle={
                                                            review.videoTitle
                                                        }
                                                        videoId={review.videoId}
                                                        isOpen={
                                                            modalStates[index]
                                                        }
                                                        onClose={() => {
                                                            closeModal(index, 1);
                                                            swiperRef1.current.swiper.autoplay.start();
                                                            swiperRef2.current.swiper.autoplay.start();
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <div className="without-image p-4 xl:p-6 flex flex-col gap-3 h-full">
                                                    <p className="lead">
                                                        {review.review}
                                                    </p>
                                                    <div className="avatar flex items-center mt-auto gap-3">
                                                        <Image loading="lazy"
                                                            src={review.image}
                                                            alt="Avatar"
                                                            className="avatar"
                                                        />
                                                        <span className="font-semibold">
                                                            {review.name}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                            <div className="happy-customers-slider-two happy-customers-slider col-span-1 flex flex-col gap-4 lg:col-span-1">
                                <Swiper
                                    ref={swiperRef2}
                                    slidesPerView={1.5}
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    direction="vertical"
                                    speed={3000}
                                    modules={[Autoplay]}
                                    autoplay={{
                                        delay: 1,
                                        disableOnInteraction: false,
                                        waitForTransition: true,
                                        pauseOnMouseEnter: true,
                                        reverseDirection: true,
                                    }}
                                    loop={true}
                                    className="swiper-container"
                                    onSlideChange={(e) => { }}
                                    onSwiper={(swiper) => { }}
                                    autoHeight={true}
                                    height={400}
                                >
                                    {REVIEWS2?.map((review, index) => (
                                        <SwiperSlide
                                            key={review.id}
                                            className={`swiper-slide ${review.showImage
                                                ? "with-image-h"
                                                : "without-image-h"
                                                }`}
                                            style={{
                                                height: "100% !important",
                                                color: "red",
                                            }}
                                        >
                                            {review.showImage ? (
                                                <div className="with-image h-full">
                                                    {review.videoId ? (
                                                        <div
                                                            className="picture object-fill h-full"
                                                            onClick={() =>
                                                                openModal(index, 2)
                                                            }
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            <Image loading="lazy"
                                                                src={
                                                                    review.thumbnail
                                                                }
                                                                alt="Picture"
                                                                className="picture object-fill h-full"
                                                            />
                                                            <div
                                                                className="play-button"
                                                                style={{
                                                                    position:
                                                                        "absolute",
                                                                    top: "50%",
                                                                    left: "50%",
                                                                    transform:
                                                                        "translate(-50%, -50%)",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                <svg
                                                                    width="61"
                                                                    height="61"
                                                                    viewBox="0 0 61 61"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <circle
                                                                        cx="30.5"
                                                                        cy="30.5"
                                                                        r="30.5"
                                                                        fill="#FFF3ED"
                                                                    />
                                                                    <path
                                                                        d="M42 30L24 40.3923L24 19.6077L42 30Z"
                                                                        fill="#FF4514"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <Image loading="lazy"
                                                            src={review.image}
                                                            alt="Picture"
                                                            className="picture object-fill h-full"
                                                        />
                                                    )}

                                                    <VideoModal
                                                        videoTitle={
                                                            review.videoTitle
                                                        }
                                                        videoId={review.videoId}
                                                        isOpen={
                                                            modal2States[index]
                                                        }
                                                        onClose={() => {
                                                            closeModal(index, 2);
                                                            swiperRef1.current.swiper.autoplay.start();
                                                            swiperRef2.current.swiper.autoplay.start();
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <div className="without-image p-4 xl:p-6 flex flex-col gap-3 h-full">
                                                    <p className="lead">
                                                        {review.review}
                                                    </p>
                                                    <div className="avatar flex items-center mt-auto gap-3">
                                                        <Image loading="lazy"
                                                            src={review.image}
                                                            alt="Avatar"
                                                            className="avatar"
                                                        />
                                                        <span className="font-semibold">
                                                            {review.name}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                    <div className="happy-customers-slider happy-customers-slider-horizontal col-span-1 flex flex-col gap-4 lg:col-span-1 mt-4 h-32">
                        <Swiper
                            slidesPerView={1.5}
                            spaceBetween={30}
                            centeredSlides={true}
                            direction="horizontal"
                            speed={3000}
                            modules={[Autoplay]}
                            autoplay={{
                                delay: 1,
                                disableOnInteraction: false,
                                waitForTransition: true,
                                pauseOnMouseEnter: true,
                            }}
                            loop={true}
                            className="swiper-container"
                            autoHeight={false}
                            height={100}
                        >
                            {REVIEWS1?.map((review, index) => (
                                <SwiperSlide
                                    key={review.id}
                                    className={`swiper-slide ${review.showImage
                                        ? "with-image-h"
                                        : "without-image-h"
                                        }`}
                                    style={{
                                        height: "100% !important",
                                        color: "red",
                                    }}
                                >
                                    {review.showImage ? (
                                        <div className="with-image h-full">
                                            {review.videoId ? (
                                                <div
                                                    className="picture object-fill h-full"
                                                    onClick={() =>
                                                        openModal(index, 1)
                                                    }
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <Image loading="lazy"
                                                        src={review.thumbnail}
                                                        alt="Picture"
                                                        className="picture object-fill h-full"
                                                    />
                                                    <div
                                                        className="play-button"
                                                        style={{
                                                            position:
                                                                "absolute",
                                                            top: "50%",
                                                            left: "50%",
                                                            transform:
                                                                "translate(-50%, -50%)",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <svg
                                                            width="61"
                                                            height="61"
                                                            viewBox="0 0 61 61"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <circle
                                                                cx="30.5"
                                                                cy="30.5"
                                                                r="30.5"
                                                                fill="#FFF3ED"
                                                            />
                                                            <path
                                                                d="M42 30L24 40.3923L24 19.6077L42 30Z"
                                                                fill="#FF4514"
                                                            />
                                                        </svg>
                                                    </div>
                                                </div>
                                            ) : (
                                                <Image loading="lazy"
                                                    src={review.image}
                                                    alt="Picture"
                                                    className="picture object-fill h-full"
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <div className="without-image p-4 xl:p-6 flex flex-col gap-3 h-full">
                                            <p className="lead">
                                                {review.review}
                                            </p>
                                            <div className="avatar flex items-center mt-auto gap-3">
                                                <Image loading="lazy"
                                                    src={review.image}
                                                    alt="Avatar"
                                                    className="avatar"
                                                />
                                                <span className="font-semibold">
                                                    {review.name}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </SwiperSlide>
                            ))}
                            {REVIEWS2?.map((review, index) => (
                                <SwiperSlide
                                    key={review.id}
                                    className={`swiper-slide ${review.showImage
                                        ? "with-image-h"
                                        : "without-image-h"
                                        }`}
                                    style={{
                                        height: "100% !important",
                                        color: "red",
                                    }}
                                >
                                    {review.showImage ? (
                                        <div className="with-image h-full">
                                            {review.videoId ? (
                                                <div
                                                    className="picture object-fill h-full"
                                                    onClick={() =>
                                                        openModal(index, 2)
                                                    }
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <Image loading="lazy"
                                                        src={review.thumbnail}
                                                        alt="Picture"
                                                        className="picture object-fill h-full"
                                                    />
                                                    <div
                                                        className="play-button"
                                                        style={{
                                                            position:
                                                                "absolute",
                                                            top: "50%",
                                                            left: "50%",
                                                            transform:
                                                                "translate(-50%, -50%)",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <svg
                                                            width="61"
                                                            height="61"
                                                            viewBox="0 0 61 61"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <circle
                                                                cx="30.5"
                                                                cy="30.5"
                                                                r="30.5"
                                                                fill="#FFF3ED"
                                                            />
                                                            <path
                                                                d="M42 30L24 40.3923L24 19.6077L42 30Z"
                                                                fill="#FF4514"
                                                            />
                                                        </svg>
                                                    </div>
                                                </div>
                                            ) : (
                                                <Image loading="lazy"
                                                    src={review.image}
                                                    alt="Picture"
                                                    className="picture object-fill h-full"
                                                />
                                            )
                                            }
                                        </div>
                                    ) : (
                                        <div className="without-image p-4 xl:p-6 flex flex-col gap-3 h-full">
                                            <p className="lead">
                                                {review.review}
                                            </p>
                                            <div className="avatar flex items-center mt-auto gap-3">
                                                <Image loading="lazy"
                                                    src={review.image}
                                                    alt="Avatar"
                                                    className="avatar"
                                                />
                                                <span className="font-semibold">
                                                    {review.name}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HappyCustomers;
